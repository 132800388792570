import { Suspense, lazy } from 'react';

// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const VerifyCodePageForgot = Loadable(lazy(() => import('../pages/auth/VerifyCodePageForgot')));
export const TwoFactorPage = Loadable(lazy(() => import('../pages/auth/TwoFactorPage')));

// HOME
export const Home = Loadable(lazy(() => import('../pages/Home')));
export const LogsPage = Loadable(lazy(() => import('../pages/LogsPage')));

// MANAGEMTNT
export const UserListPage = Loadable(lazy(() => import('../pages/management/UserListPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/management/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/management/UserEditPage')));

// NAD V3
export const NadHomePage = Loadable(lazy(() => import('../pages/nadapp_v3/NadHomePage')));
export const AdminPanelPage = Loadable(lazy(() => import('../pages/nadapp_v3/AdminPanelPage')));
export const NadSingleClientPage = Loadable(lazy(() => import('../pages/nadapp_v3/NadSingleClientPage')));
export const NadEmailSettings = Loadable(lazy(() => import('../pages/nadapp_v3/NadEmailSettings')));
export const NadWhitelist = Loadable(lazy(() => import('../pages/nadapp_v3/NadWhitelist')));
export const NadBackupPage = Loadable(lazy(() => import('../pages/nadapp_v3/BackupPage')));

// MAP
export const MapHomePage = Loadable(lazy(() => import('../pages/map/MapHomePage')));
export const MapJobsPage = Loadable(lazy(() => import('../pages/map/MapJobsPage')));
export const MapEditNewClient = Loadable(lazy(() => import('../pages/map/MapEditNewClient')));
export const MapSchedulingPage = Loadable(lazy(() => import('../pages/map/MapSchedulingPage')));
export const MapStatisticsPage = Loadable(lazy(() => import('../pages/map/MapStatisticsPage')));

// ASSENZE
export const FerieCalendarPage = Loadable(lazy(() => import('../pages/assenze/FerieCalendarPage')));

// CALENDARIO
export const CalendarPage = Loadable(lazy(() => import('../pages/calendar/CalendarPage')));

// BACHECA
export const BachecaHomePage = Loadable(lazy(() => import('../pages/bacheca/BachecaHomePage')));

// SCARICAMENTI
export const ScaricamentiPage = Loadable(lazy(() => import('../pages/scaricamenti/ScaricamentiPage')));
export const ClientiPage = Loadable(lazy(() => import('../pages/scaricamenti/ClientiPage')));
export const FacetsPage = Loadable(lazy(() => import('../pages/scaricamenti/FacetsPage')));
export const SchedulingPage = Loadable(lazy(() => import('../pages/scaricamenti/SchedulingPage')));

// MANAGEMENT
export const PrenotazioniPage = Loadable(lazy(() => import('../pages/management/PrenotazioniPage')));
export const TicketPage = Loadable(lazy(() => import('../pages/management/TicketPage')));
export const NewTicketPage = Loadable(lazy(() => import('../pages/management/NewTicketPage')));
export const SingleTicketPage = Loadable(lazy(() => import('../pages/management/SingleTicketPage')));
export const NewChangelogPage = Loadable(lazy(() => import('../pages/management/NewChangelogPage')));
export const SingleChangelogPage = Loadable(lazy(() => import('../pages/management/SingleChangelogPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/management/UserAccountPage')));
export const AnalystSupportPage = Loadable(lazy(() => import('../pages/management/AnalystSupportPage')));
export const ManagementPage = Loadable(lazy(() => import('../pages/management/ManagementPage')));
export const ClientPage = Loadable(lazy(() => import('../pages/management/ClientPage')));
export const InternalPhones = Loadable(lazy(() => import('../pages/management/InternalPhones')));

// CRAWLING
export const CrawlingHomePage = Loadable(lazy(() => import('../pages/crawling/CrawlingHomePage')));

// RAWDATA
export const RawDataPage = Loadable(lazy(() => import('../pages/rawdata/RawDataPage')));
export const RawDataClientPage = Loadable(lazy(() => import('../pages/rawdata/RawDataClientPage')));

// RISORSE UMANE
export const RisorseUmaneHomePage = Loadable(lazy(() => import('../pages/risorseumane/RisorseUmaneHomePage')));

// DNS
export const DiscoverPage = Loadable(lazy(() => import('../pages/dns/DiscoveryPage')));
export const SummaryPage = Loadable(lazy(() => import('../pages/dns/SummaryPage')));
export const SummaryDetailsPage = Loadable(lazy(() => import('../pages/dns/SummaryDetailPage')));

// MARKETPLACE
export const MarketplaceHomePage = Loadable(lazy(() => import('../pages/marketplace/MarketplaceHomePage')));
export const MarketplaceEditClientPage = Loadable(lazy(() => import('../pages/marketplace/MarketplaceEditClientPage')));
export const MarketplaceSingleTablePage = Loadable(lazy(() => import('../pages/marketplace/MarketplaceSingleTablePage')));
export const MarketplaceProjectsPage = Loadable(lazy(() => import('../pages/marketplace/MarketplaceProjectsPage')));
export const MarketplaceSingleTableSettingsPage = Loadable(lazy(() => import('../pages/marketplace/MarketplaceSingleTableSettingsPage')));

// IDENTITA DIGIALE
export const IdentitàDigitalePage = Loadable(lazy(() => import('../pages/identity/IdentitàDigitalePage')));
export const IdentitàDigitaleClientPage = Loadable(lazy(() => import('../pages/identity/IdentitàDigitaleClientPage')));

// WIKI
export const WikiPage = Loadable(lazy(() => import('../pages/wiki/WikiPage')));
export const WikiCategoryPage = Loadable(lazy(() => import('../pages/wiki/WikiCategoryPage')));
export const WikiArticlePage = Loadable(lazy(() => import('../pages/wiki/WikiArticlePage')));
export const WikiAddNewArticlePage = Loadable(lazy(() => import('../pages/wiki/WikiAddNewArticlePage')));

// STREAM
export const StreamPage = Loadable(lazy(() => import('../pages/stream/view')));

// SOCIALS
export const SocialsPage = Loadable(lazy(() => import('../pages/socialsContents/view')));
export const SocialsHome = Loadable(lazy(() => import('../pages/socialsContents/home')));
export const SocialsJobs = Loadable(lazy(() => import('../pages/socialsContents/jobs')));

// UDRP
export const UdrpPage = Loadable(lazy(() => import('../pages/udrp/view')));

// GALLERY
export const GalleryPage = Loadable(lazy(() => import('../pages/gallery/view')));
export const JobView = Loadable(lazy(() => import('../pages/gallery/job-view')));

// SIMILARITY
export const ClientsSimilarityPage = Loadable(lazy(() => import('../pages/match/match-clients-list')));
export const JobsListSimilarityPage = Loadable(lazy(() => import('../pages/match/match-client-jobs')));
export const MatchJob = Loadable(lazy(() => import('../pages/match/match-job')));

// DIFFIDE
export const DiffideHome = Loadable(lazy(() => import('../pages/diffide/view-home')));
export const DamageCompensationPage = Loadable(lazy(() => import('../pages/diffide/view-damage-compensations')));
export const ClientSettingsPage = Loadable(lazy(() => import('../pages/diffide/client-settings-page')));

// TRACCIAMENTO
export const TracciamentoHome = Loadable(lazy(() => import('../pages/tracciamento/home')));
export const TracciamentoSettings = Loadable(lazy(() => import('../pages/tracciamento/settings')));
export const TrendTemporale = Loadable(lazy(() => import('../pages/tracciamento/trend_temporale')));

// HELPDESK
export const HelpDeskPage = Loadable(lazy(() => import('../pages/helpDesk/HelpDeskPage')));

// TIENAMMENT
export const TienammentHomePage = Loadable(lazy(() => import('../pages/tienamment/page')));

// CROM
export const CromHome = Loadable(lazy(() => import('../pages/crom/crom-home')));
export const CromAdd = Loadable(lazy(() => import('../pages/crom/crom-add')));

// CONVEY LENS
export const ConveyLensClients = Loadable(lazy(() => import('../pages/lens/clients-page')));
export const LensDetailsPage = Loadable(lazy(() => import('../pages/lens/details-page')));



// ERRORS
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
