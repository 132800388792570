// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT_APP = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  reset_pwd: '/reset-password',
  two_factor: '/two-factor',
};

export const PATH_APP = {
  root: ROOT_APP,
  home: path(ROOT_APP, '/home'),
  analystsupport: path(ROOT_APP, '/analystsupport'),
  logs: path(ROOT_APP, '/logs'),
  identita: path(ROOT_APP, '/identita'),
  identita_client: (client_name) => path(ROOT_APP, `/identita/${client_name}`),
  manageaccount: path(ROOT_APP, `/account/profile`),
  usermngt: path(ROOT_APP, '/mngt/users'),
  clientsmngt: path(ROOT_APP, '/mngt/clients'),
  internalPhonesMngt: path(ROOT_APP, '/mngt/phones'),
  mngt_page: path(ROOT_APP, '/mngt'),
  newuser: path(ROOT_APP, '/mngt/new-user'),
  edit: (name) => path(ROOT_APP, `/mngt/${name}/edit`),
  nad: {
    home: path(ROOT_APP, '/nadv3/home'),
    admin_panel: path(ROOT_APP, '/nadv3/adminpanel'),
    backup_panel: path(ROOT_APP, '/nadv3/backuppanel'),
    singleClientPage: (client_name) => path(ROOT_APP, `/nadv3/${client_name}/table`),
  },
  marketplace: {
    home: path(ROOT_APP, '/marketplace/home'),
    edit: (id) => path(ROOT_APP, `/marketplace/${id}/edit`),
    tablelist: (id) => path(ROOT_APP, `/marketplace/${id}/table`),
    table: (id, table) => path(ROOT_APP, `/marketplace/${id}/${table}/table`),
    settings: (id, table) => path(ROOT_APP, `/marketplace/${id}/${table}/settings`),
  },
  hr: {
    home: path(ROOT_APP, '/hr/home'),
  },
  udrp: {
    home: path(ROOT_APP, '/udrp/home'),
  },
  diffide: {
    home: path(ROOT_APP, '/diffide/home'),
    damagecompensation: path(ROOT_APP, '/diffide/damagecompensation'),
    clients_settings: path(ROOT_APP, '/diffide/clients-settings'),
  },
  crawling: {
    home: path(ROOT_APP, '/crawling/home'),
  },
  map: {
    home: path(ROOT_APP, '/map/home'),
    new: path(ROOT_APP, `/map/new`),
    edit: (client) => path(ROOT_APP, `/map/${client}/edit`),
    jobs: path(ROOT_APP, `/map/jobs`),
    clientjobs: (client) => path(ROOT_APP, `/map/${client}/jobs`),
    scheduling: (client) => path(ROOT_APP, `/map/${client}/scheduling`),
    allscheduling: path(ROOT_APP, `/map/scheduling`),
    stats: path(ROOT_APP, `/map/stats`),
  },
  scaricamenti: {
    home: path(ROOT_APP, '/scaricamenti/home'),
    clienti: path(ROOT_APP, '/scaricamenti/clienti'),
    scheduling: path(ROOT_APP, '/scaricamenti/scheduling'),
    facets: path(ROOT_APP, '/scaricamenti/facets'),
    discovery: path(ROOT_APP, '/crom/discovery'),
  },
  crom: {
    home: path(ROOT_APP, '/crom/home'),
    discovery: path(ROOT_APP, '/crom/discovery'),
    add: path(ROOT_APP, '/crom/add'),
  },
  prenotazioni: {
    home: path(ROOT_APP, '/prenotazioni/home'),
  },
  tracciamento: {
    home: path(ROOT_APP, '/rendicontazione/home'),
    settings: path(ROOT_APP, '/rendicontazione/settings'),
    trendTemporale : path(ROOT_APP, '/rendicontazione/trend-temporale'),
  },
  assenze: {
    home: path(ROOT_APP, '/assenze/home'),
  },
  bacheca: {
    home: path(ROOT_APP, '/bacheca/home'),
  },
  calendario: {
    home: path(ROOT_APP, '/calendario'),
  },
  ticket: {
    home: path(ROOT_APP, '/ticket/home'),
    new: path(ROOT_APP, '/ticket/new'),
    page: (id) => path(ROOT_APP, `/ticket/page/${id}`),
  },
  news: {
    new: path(ROOT_APP, '/news/new'),
    page: (id) => path(ROOT_APP, `/news/page/${id}`),
  },
  dns: {
    discovery: path(ROOT_APP, '/dns/discovery'),
    summary: path(ROOT_APP, '/dns/summary'),
    summarydetail: (id) => path(ROOT_APP, `/dns/summary/detail/${id}`),
  },
  socials: {
    home: path(ROOT_APP, '/designs/home'),
    jobs: (client_name, design_id) => path(ROOT_APP, `/designs/${client_name}/${design_id}/jobs`),
    list: (client_name, job_id) => path(ROOT_APP, `/designs/${client_name}/${job_id}`),
  },
  stream: path(ROOT_APP, '/stream'),
  gallery: path(ROOT_APP, '/gallery'),
  galleryJob: (id) => path(ROOT_APP, `/gallery/${id}`),
  wiki: {
    home: path(ROOT_APP, '/wiki/home'),
    category: (id) => path(ROOT_APP, `/wiki/category/${id}`),
    article: (id) => path(ROOT_APP, `/wiki/article/${id}`),
    new: path(ROOT_APP, '/wiki/new'),
  },
  rawdata: {
    home: path(ROOT_APP, '/rawdata/home'),
    client: (name) => path(ROOT_APP, `/rawdata/client/${name}`),
  },
  similarity: {
    clients: path(ROOT_APP, '/match/clients'),
    jobsList: (client_name) => path(ROOT_APP, `/match/${client_name}`),
    job: (client_name, job_id) => path(ROOT_APP, `/match/${client_name}/${job_id}`),
  },
  convey_lens: {
    clients: path(ROOT_APP, '/g8/clients'),
    details: (client_name) => path(ROOT_APP, `/g8/${client_name}`),
  },
  help_desk: {
    home: path(ROOT_APP, '/help-desk'),
  },
};
